<template>
    <div class="paging-content">
        <ul class="paging-body pl-0">
            <li v-if="pageNum > 1" @click="prePage" class="pr-1">上一页</li>
            <!--小于minPage的数据全部显示-->
            <li @click="changePage(item)" v-for="(item,index) in totalsList" class="pr-1"
                :class="{'activate-page':item === pageNum}" :key="index">
                {{item}}
            </li>
            <li v-if="pageNum < totalPages" class="pr-1" @click="nextPage">下一页</li>
        </ul>

    </div>
</template>

<script>
  export default {
    name: "Paging",
    data() {
      return {
        minPage: 6,
        currentPage: 0,
        totalsList: []
      }
    },
    props: {
      comments_id: {
        type: Number
      },
      pageNum: {
        type: Number
      },
      totalPages: {
        type: Number
      },
    },


    created() {
      this.updateTotalsList()
    },
    methods: {
      changePage(page) {
        if (page !== '...' && page !== this.pageNum) {
          this.currentChange(page)
        }
      },
      prePage() {
        this.currentChange(this.pageNum - 1)

      },
      nextPage() {
        this.currentChange(this.pageNum + 1)
      },
      currentChange(page) {
        this.$emit("currentChange", page, this.comments_id)
      },
      updateTotalsList() {
        let totalsList = []
        // 如果pageNum 在前三页
        if (this.totalPages <= this.minPage) {
          for (let i = 1; i <= this.totalPages; i++) {
            totalsList.push(i)
          }
        } else if (this.pageNum <= 4) {
          totalsList = [1, 2, 3, 4, 5, '...', this.totalPages]
        } else if (this.pageNum >= 3 && this.pageNum + 2 < this.totalPages) {
          totalsList = [1, '...', this.pageNum - 1, this.pageNum, this.pageNum + 1, '...', this.totalPages]
        } else if (this.pageNum + 2 >= this.totalPages) {
          totalsList = [1, '...', this.totalPages - 3, this.totalPages - 2, this.totalPages - 1, this.totalPages]
        }
        this.totalsList = totalsList
      }
    },
    watch: {
      pageNum(lodVal, newVal) {
        if (lodVal !== newVal) {
          this.updateTotalsList()
        }

      },
    },
  }
</script>

<style scoped>

    .paging-content {

    }

    .paging-body {
        display: flex;
        text-align: center;
        font-size: 0.4rem;
    }

    .paging-body li {
        margin: 0 !important;
    }

    .activate-page {
        color: #03a9f4;
    }

    @media screen and (max-width: 750px){
        .paging-body {
            font-size: 0.8rem;
        }

    }
</style>