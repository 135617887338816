<template>
    <div class="comment-form">
        <v-form :v-model="true">
            <v-row>
                <div class="lamu-image comment-from-image" :class="{'focus-lamu':focusLamu}">
                    <img src="https://zwb-typora.oss-cn-hangzhou.aliyuncs.com/images/lamu222.png" alt="">
                </div>
                <div class="leimu-image comment-from-image" :class="{'focus-leimu':focusLeimu}">
                    <img src="https://zwb-typora.oss-cn-hangzhou.aliyuncs.com/images/leimu22.png" alt="">
                </div>
                <v-textarea
                        @blur="loseBlur"
                        @focus="getFocus"
                        class="comment-info pl-1"
                        name="commentInfo"
                        clearable
                        :rows=rows
                        :label=" reply_name.length ===0 ?'说点什么吧':'@'+reply_name"
                        style="font-size: 1.4rem"
                        v-model="commentFrom.commentInfo"
                ></v-textarea>
            </v-row>
            <v-row>
                <v-col style="color: #49b1f5" class="pa-0 pr-1" cols="12" md="4">
                    <v-text-field @blur="loseBlur" @focus="getFocus" prepend-icon="mdi-account" clearable
                                  v-model="commentFrom.username"
                                  label="昵称"
                                  required></v-text-field>
                </v-col>
                <v-col class="pa-0 pl-1" cols="12" md="4">
                    <v-text-field @blur="loseBlur" @focus="getFocus" prepend-icon="mdi-email" clearable
                                  v-model="commentFrom.email"
                                  label="邮箱"
                                  required></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-btn :disabled='add_disabled' block class="comment-btn" color="#4293c4" @click="submitComments">发送
                    </v-btn>
                </v-col>
            </v-row>

        </v-form>
    </div>
</template>

<script>
  import {createCommentsApi} from "@/network/interface"

  export default {
    name: "Comment",
    data() {
      return {
        add_disabled: false,
        focusLamu: false,
        focusLeimu: false
      }
    },
    props: {
      rows: {
        type: Number,
        default() {
          return 4
        }
      },
      // 文章id
      article_id: {
        default() {
          return ""
        }
      },
      // 父评论id
      father_id: {
        default() {
          return ""
        }
      },
      // 回复评论id
      reply_id: {
        default() {
          return ""
        }
      },
      // 回复人名称
      reply_name: {
        default() {
          return ""
        }
      },
      comments_type: {
        default() {
          return 0 // 默认是文章
        }
      },

      commentFrom: {
        default() {
          return {
            username: '',
            email: '',
            commentInfo: ''
          }
        }
      },
    },
    methods: {
      getFocus() {
        this.focusLamu = true
        this.focusLeimu = true
      },
      loseBlur() {
        this.focusLamu = false
        this.focusLeimu = false
      },
      submitComments() {
        // 检查数据

        const ret = this.checkData()
        this.add_disabled = true
        if (ret[0]) {
          const data = ret[1]
          // 添加文章id
          if (this.article_id !== 0 && this.comments_type === 0) {
            data.article_id = this.article_id
          }

          // 添加父评论
          if (this.father_id.length !== '') {
            data.father_id = this.father_id
          }

          // 添加回复评论
          if (this.reply_id !== '') {
            data.reply_id = this.reply_id
          }

          data.comments_type = this.comments_type

          // 发送数据
          createCommentsApi(JSON.parse(JSON.stringify(data))).then(resp => {
            this.$toast({message: "评论成功~~", state: "success"})
            this.empty_content()
            this.add_disabled = false
            this.$emit('commentsSuccess', resp.data)


          }, error => {
            this.add_disabled = false
            this.$toast({message: "评论失败了~~~", state: "success"})
          })

        }
        this.add_disabled = false
      },
      empty_content() {
        this.commentFrom.commentInfo = ''
        this.commentFrom.username = ''
        this.commentFrom.email = ''
      },
      checkData() {
        // 检查内容不能空
        if (this.commentFrom.commentInfo.length === 0) {
          this.$toast({message: "评论内容为空~~", state: "error"})
          return [false, null]
        }

        if (this.commentFrom.username.length === 0) {
          this.$toast({message: "名称为空~~", state: "error"})
          return [false, null]
        }

        if (!(/^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(this.commentFrom.username))) {
          this.$toast({message: "请正确填写名称哦~~", state: "error"})
          return [false, null]
        }

        if (this.commentFrom.email.length === 0) {
          this.$toast({message: "邮箱地址为空~~", state: "error"})
          return [false, null]
        }

        if (!(/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.commentFrom.email))) {
          this.$toast({message: "请正确填写邮箱地址哦~~", state: "error"})
          return [false, null]
        }

        return [true, {
          name: this.commentFrom.username,
          email: this.commentFrom.email,
          content: this.commentFrom.commentInfo
        }]
      }
    }
  }
</script>

<style scoped>
    /*评论*/

    .comment-form {
        overflow: hidden;
        position: relative;
        padding: 60px 30px 30px 30px;
        border-radius: 5px;
        border: 2px solid #49b1f5;
    }

    .comment-from-image {
        width: 60px !important;
        height: 60px !important;
        transform: rotate(180deg);
        position: absolute;
        transition: all 0.5s;
        overflow: hidden;
    }

    .comment-from-image img {
        width: 100%;
        height: 100%;
    }

    .lamu-image {
        left: -10px;
        top: -20px;
    }

    .leimu-image {
        right: -10px;
        top: -20px;
    }

    .lamu-image img,
    .leimu-image img {
        background-color: transparent !important;
        transform: scale(1.1);
    }


    .focus-lamu {
        left: 0;
        top: 0;
    }

    .focus-leimu {
        right: 0;
        top: 0;
    }

    .comment-btn {
        color: #ffffff;
        background: linear-gradient(-45deg, #06a1ec, #49b1f5);
    }


</style>