<template>
    <div>
        <div class="comment-list">
            <div class="comment-list-item comment-list-item-surface" v-for="comment in commentData"
                 :key="comment.id">
                <div class="comment-reply">
                    <v-icon>mdi-thumb-up-outline</v-icon>
                    <span class="mr-2">{{comment.comment_like}}</span>
                    <v-icon @click="commentReply = comment.id">mdi-comment-processing</v-icon>
                </div>
                <div class="comment-info">
                    <v-avatar
                            :size="60"
                            color="accent"
                    ><img :src="comment.title_image"></v-avatar>
                    <div class="comment-info-desc">
                        <span v-if="comment.is_master === 1"
                              class="comment-name comment-name-master">{{comment.name}}</span>
                        <span v-else class="comment-name">{{comment.name}}</span>
                        <span class="comment-time">{{comment.create_time}}</span>
                        <div class="comment-content">
                            {{comment.content}}
                        </div>
                    </div>
                </div>
                <div v-if="commentReply === comment.id" class="comment-reply-comment">
                    <v-icon class="comment-close" @click="commentReply = ''">mdi-close-circle</v-icon>
                    <comment
                            @commentsSuccess="commentsSuccess"
                            :comments_type="comments_type"
                            :reply_name="comment.name"
                            :father_id="comment.id"
                            :article_id="articles_id"
                            :rows="2"/>
                </div>
                <div class="comment-list comment-children">
                    <div class="comment-list-item" v-for="comment_child in comment.children.data"
                         :key="comment_child.id">
                        <div class="comment-reply">
                            <v-icon>mdi-thumb-up-outline</v-icon>
                            <span class="mr-2">{{comment_child.comment_like}}</span>
                            <v-icon @click="commentReply = comment_child.id">mdi-comment-processing</v-icon>
                        </div>
                        <div class="comment-info">
                            <v-avatar
                                    :size="40"
                                    color="accent"
                            ><img :src="comment_child.title_image"></v-avatar>
                            <div class="comment-info-desc">
                                <span v-if="comment_child.is_master === 1" class="comment-name comment-name-master">{{comment_child.name}}</span>
                                <span v-else class="comment-name">{{comment_child.name}}</span>
                                <span class="comment-time">{{comment_child.create_time}}</span>
                                <div class="comment-content">
                                    <span class="comment-reply-name mr-1" v-if="comment_child.reply_id !== ''">
                                       @{{comment_child.reply_name}}:
                                    </span>
                                    {{comment_child.content}}
                                </div>
                            </div>
                        </div>
                        <div v-if="commentReply === comment_child.id" class="comment-reply-comment">
                            <v-icon class="comment-close" @click="commentReply = ''">mdi-close-circle</v-icon>
                            <comment
                                    @commentsSuccess="commentsSuccess"
                                    :comments_type="comments_type"
                                    :father_id="comment.id"
                                    :reply_id="comment_child.id"
                                    :reply_name="comment_child.name"
                                    :article_id="articles_id" :rows="2"/>
                        </div>
                    </div>
                    <div class="child-comment-load"
                         v-if="comment.children.page_info.pageNum < comment.children.page_info.totalPages && load_follows['load'+comment.id+comments_type] === undefined">
                        共{{comment.children.page_info.total}}条评论，<span
                            @click="loadCommentsChildren(comment.children.page_info.pageNum +1,comment.id)">加载更多</span>
                    </div>

                    <div class="children-paging" v-if="load_follows['load' + comment.id + comments_type] === true">
                        <paging @currentChange="currentChange"
                                :comments_id="comment.id"
                                :page-num="comment.children.page_info.pageNum"
                                :total-pages="comment.children.page_info.totalPages"></paging>
                    </div>
                </div>

            </div>
            <div class="load-comment"
                 v-if="commentsPageInfo.total !==0 && commentsPageInfo.totalPages !== commentsPageInfo.pageNum">
                <v-btn :loading="disabled_load" dark block color="#3caef3" @click="nextPageData">更多评论</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
  import Comment from './comment'
  import Paging from "@/components/Paging"

  export default {
    name: "commentList",
    data() {
      return {
        page: 1,
        disabled_load: false,
        commentReply: '',
        load_follows: {}
      }
    },
    props: {
      comments_type: {
        type: Number,
        default() {
          return 0 // 默认文章
        }
      },
      articles_id: {
        type: Number,
        default() {
          return 0
        }
      },
      commentData: {
        type: Array,
        default() {
          return []
        }
      },
      commentsPageInfo: {
        type: Object,
        default() {
          return {
            pageNum: 1,
            total: 0,
            totalPages: 1,
          }
        }
      }
    },
    watch: {
      commentData(lodv, newv) {
        this.disabled_load = false
      }
    },
    methods: {
      // 生成随机字符串
      nextPageData() {
        this.$emit('nextPageData')
        this.disabled_load = true
      },

      loadCommentsChildren(pageNum, father_id) {
        this.load_follows['load' + father_id + this.comments_type] = true
        this.currentChange(pageNum, father_id)
      },
      currentChange(pageNum, father_id) {
        this.$emit('nextChildrenPageData', father_id, pageNum)
      },
      // 二级评论添加
      commentsSuccess(data) {
        this.currentChange(1, data.father_id)
      }
    },
    components: {
      Comment,
      Paging
    }
  }
</script>

<style scoped>


    .comment-list-item {
        position: relative;
        padding: 10px 0;
        margin-bottom: 10px;
    }

    .comment-list-item-surface {
        border-bottom: 1px dashed #dfe8ec;
    }


    .comment-reply {
        color: #ef2f11;
        float: right;
    }

    .comment-reply > span {
        display: inline-block;
        text-align: center;
        font-size: 0.8rem;
        width: 20px;
    }

    .comment-reply .v-icon {
        font-size: 0.8rem;
        color: #ef2f11;

    }


    .comment-info {
        display: flex;
    }


    .comment-info-desc {
        flex: 1;
        margin-left: 10px;
    }

    .comment-name {
        font-weight: 700;
        font-size: 1.1rem;
    }

    .comment-name-master {
        display: inline-block;
        padding: 0 2px;
        background-color: #f8bd43 !important;
        color: #ffffff;
        border-radius: 3px;
    }

    .comment-time {
        font-size: 0.8rem;
        color: #4c4948;
        margin-left: 10px;
    }

    .theme--dark .comment-time {
        color: #eeeeee;
    }

    .comment-content {
        font-size: 0.9rem;
        padding-bottom: 20px;
        padding-top: 10px;
        color: #98766a !important;
    }

    .comment-reply-comment {
        position: relative;
        padding: 10px 0 10px 0;
    }


    .comment-close {
        right: 0;
        top: -15px;
        position: absolute;
    }

    .comment-children {
        margin-left: 50px;
    }

    .comment-children .comment-reply-name {
        color: #FDBE3D;
    }

    .comment-children .comment-name {
        font-size: 0.8rem;
    }

    .comment-children .comment-time {
        font-size: 0.5rem;
    }

    .comment-children .comment-content {
        font-size: 0.5rem;
        padding: 10px 0;
    }


    .comment-children .comment-list-item {
        padding: 0 0;
    }


    .child-comment-load {
        font-size: 0.5rem;
    }

    .child-comment-load span {
        color: #03a9f4;
    }

    @media screen and (max-width: 600px) {

        .comment-list-item {
            padding: 5px 0;
        }

        .comment-name {
            font-size: 1rem;
        }


        .comment-content {
            font-size: 0.8rem;
            padding: 10px 0;
        }

        .comment-info-desc {
            margin-left: 10px;
            margin-bottom: 5px;
        }

        .comment-children {
            margin-left: 20px;
        }

        .comment-reply-comment {
            padding-left: 10px;
        }

        .comment-children .comment-content {
            font-size: 0.7rem;
        }
    }
</style>