import {getCommentsListApi, getCommentsChildrenListApi} from "@/network/interface";

export const CommentsDataMixin = {
  data() {
    return {
      articlesCommentsData: {
        pageInfo: {
          pageNum: 1,
          total: 0,
          totalPages: 1,
          comments_count: 1,
        },
        data: [],
      }
    }
  },
  methods: {
    // 获取文章评论数据
    getCommentsList(params) {
      getCommentsListApi(params).then(resp => {
        this.articlesCommentsData.pageInfo.pageNum = resp.data.pageNum
        this.articlesCommentsData.pageInfo.total = resp.data.total
        this.articlesCommentsData.pageInfo.totalPages = resp.data.totalPages
        this.articlesCommentsData.data = resp.data.result
        this.articlesCommentsData.pageInfo.comments_count = resp.data.comments_count
      })
    },
    // 获取下一页评论数据
    nextPageData(params) {
      if (this.articlesCommentsData.pageInfo.totalPages !== this.articlesCommentsData.pageInfo.pageNum) {
        getCommentsListApi(params).then(resp => {
          this.articlesCommentsData.pageInfo.pageNum = resp.data.pageNum
          this.articlesCommentsData.data = this.articlesCommentsData.data.concat(resp.data.result)
        })
      }
    },
    // 获取下一页子评论数据
    nextChildrenPageData(father_id, pageNum, comments_type, params) {
      let childrenComment
      for (let index in this.articlesCommentsData.data) {
        if (this.articlesCommentsData.data[index].id.toString() === father_id.toString()) {
          childrenComment = this.articlesCommentsData.data[index]
          break
        }
      }
      params.father_id = father_id
      params.comments_type = comments_type
      params.pageNum = pageNum
      getCommentsChildrenListApi(params).then(resp => {
        childrenComment.children.data = resp.data.result
        childrenComment.children.page_info.pageNum = resp.data.pageNum
      })


    }
  }


}